
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AgmCoreModule } from '@agm/core';

import { AppComponent } from './app.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatIconModule} from '@angular/material/icon';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDialogModule } from '@angular/material/dialog';

import { HeaderComponent, loginModel, videoModel } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { HomeComponent, modelAskPasscode } from './pages/home/home.component';
import { RoutingModule } from './routing/routing.module';
import { RouterModule } from '@angular/router';
import { TitleComponent } from './layout/title/title.component';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { RegistrationComponent } from './pages/hospital/registration/registration.component';
import { DashboardComponent } from './pages/governer/dashboard/dashboard.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MomentumTableModule } from 'momentum-table';
import { HttpClientModule } from '@angular/common/http';
import { MatSelectModule } from '@angular/material/select';
import { HereMapComponent } from './here-map/here-map.component';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { AboutComponent } from './about/about.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    TitleComponent,
    modelAskPasscode, loginModel, videoModel,
    RegistrationComponent,
    DashboardComponent,
    HereMapComponent,
    AboutComponent    
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatIconModule, MatGridListModule, MatInputModule, MatButtonModule, MatTabsModule, MatSelectModule, MatSnackBarModule,
    MatDialogModule,
    RoutingModule,
    RouterModule,
    FormsModule,
    MomentumTableModule, 
    HttpClientModule,
    GooglePlaceModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCa952cWXECVQi36D1luAAcsaxuZuymIgI'
    })
  ],
  entryComponents:[
    modelAskPasscode,
    loginModel,
    videoModel
  ],
  providers: [{ provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } }],
  bootstrap: [AppComponent]
})
export class AppModule { }
