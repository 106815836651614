import { Routes, RouterModule, Router } from '@angular/router';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '../../services/api.service';
export interface DialogData {
  animal: 'panda' | 'unicorn' | 'lion';
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  passcode;

  constructor(public dialog: MatDialog, private Router: Router, private api: ApiService) {
    if (localStorage.getItem('passcode')) {
      localStorage.clear();
    }

    if(localStorage.getItem('_id')){
      this.api.login({ '_id': localStorage.getItem('_id') });
    }
   }

  ngOnInit() {
  }

  openDialog(user:string) {
    const dialogRef = this.dialog.open(modelAskPasscode, { 
      data: {
        passcode: '',
      },
      width: '530px'});
      dialogRef.afterClosed().subscribe(result => {
          if(user=='hospital'){
            this.api.validate(result, 'hospital');
          }
          else{
            this.api.validate(result, 'admin')
          }
    });
  }

}

@Component({
  selector: 'model-ask-passcode',
  templateUrl: 'model-ask-passcode.html',
})
export class modelAskPasscode {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) { }
}