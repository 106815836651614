import { Grid } from 'ag-grid-community';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MouseEvent, AgmMap, LatLngBounds } from '@agm/core';
import { HereService } from 'src/app/services/here.service';
import { ApiService } from '../../../services/api.service';

declare var google: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements AfterViewInit {

  @ViewChild('tablex', { static: false }) tablex: ElementRef;;
  @ViewChild('AgmMap', { static: false }) agmMap: AgmMap;

  sum: number;
  state = '';
  highlight = {
    total: 0,
    inUse: 0,
    available: 0
  };
  public cities = [];
  
  mapObj: any = {
    zoom : 4,
    lat: 51.673858,
    lng: 7.815982,
    markers: [],
    map: null
  }
  
  constructor(private here: HereService, private api:ApiService) {
    console.log("dashboard", this);
  }

  ngOnInit() {
      
  }

  ngAfterViewInit() {
    this.agmMap.mapReady.subscribe(map => {
      this.mapObj.map = map;
    });

    this.api.get_hospitals().subscribe(data => {
      this.api.validateObserve(localStorage.getItem('passcode')).subscribe(data => {
        if (data && data['role'] == 'admin') {
          this.state = 'State - ' + data['state'];
          this.api.get_hospitals_filter(data['state']).subscribe(hospitals => {
              let hospitalArr = [];
            Object.keys(hospitals).forEach((id, ind) => {
              let hospital = hospitals[id];

              this.tablex['value'].push({ 
                country: hospital.hospitalDetails.country,
                state: hospital.hospitalDetails.state,
                city: hospital.hospitalDetails.city,
                county: hospital.hospitalDetails.county,
                name: hospital.hospitalDetails.name,
                total: hospital.ventilators.total,
                inUse: hospital.ventilators.inUse,
                available: hospital.ventilators.available,
                address: hospital.hospitalDetails.address,
              });

              var hObj = {
                lat: hospital.hospitalDetails.location.latitude,
                lng: hospital.hospitalDetails.location.longitude,
                name: hospital.hospitalDetails.name,
                city: hospital.hospitalDetails.city,
                county: hospital.hospitalDetails.county,
                state: hospital.hospitalDetails.state, 
                ventilators:hospital.ventilators,
                address: hospital.hospitalDetails.address
              }
              hospitalArr.push(hObj);
              
              if(ind == 0) {
                this.mapObj.lat = Number(hObj.lat);
                this.mapObj.lng = Number(hObj.lng);
              }

              this.highlight.total = this.highlight.total + hospital.ventilators.total;
              this.highlight.inUse = this.highlight.inUse + hospital.ventilators.inUse;
              this.highlight.available = this.highlight.available + hospital.ventilators.available;
            });
            this.mapObj.markers = hospitalArr;
          });
        } else {

        }
      });
    });
  }

  mapClicked(evt: MouseEvent) {
    console.log("map event clicked", evt);
  }

  onFilter(event) {
    console.log(event, ":tablex", this.tablex);
  }

  matTabClick(evt) {
     // console.log("mat tab click", evt);
      if(evt.index == 1) {
          this.fitMapBounds();
      }
  }

  fitMapBounds() {
    let bounds: LatLngBounds = new google.maps.LatLngBounds();
    for (const mm of this.mapObj.markers) {
      bounds.extend(new google.maps.LatLng(mm.lat*1, mm.lng*1));
    }
    this.mapObj.map.fitBounds(bounds);
  }

}
