<mat-toolbar color="primary" >
  <mat-toolbar-row>
    <!-- Logo -->
    <span routerLink="/home" routerLinkActive="active" class="pointer">
      <img src="../../../assets/logo-bw.png" style="max-height: 50px;">
    </span>
    <span class="example-spacer"></span>
    <mat-icon class="pointer mobile-menu" aria-hidden="false" aria-label="Logout" (click)="showMenu=!showMenu">
      {{showMenu?'close':'dehaze'}}
    </mat-icon>
    <a class="mx-2 pointer" routerLink="/" class="large-menu">Home</a>
    <a routerLink="/about" class="large-menu mx-2 pointer">About</a>
    <div *ngIf="!showlogout()" class="large-menu">
      <a class="mx-2 pointer" (click)="openDialog()">login</a>
    </div>

    <div *ngIf="showlogout()" class="large-menu">
      <a class="mx-2 pointer icon-text" (click)="logout()">
        logout <mat-icon class="pointer" aria-hidden="false" aria-label="Logout" inline=true>exit_to_app</mat-icon>
      </a>
    </div>
  </mat-toolbar-row>
</mat-toolbar>

<!-- For Mobile -->
<mat-toolbar style="background-color: #3f98d3;" class="mobile-menu" *ngIf="showMenu">
  <a class="mx-2 pointer" routerLink="/">Home</a>
  <a class="mx-2 pointer" routerLink="/about">About</a>
  <div *ngIf="!showlogout()">
    <a class="mx-2 pointer" (click)="openDialog()">login</a>
  </div>
  <div *ngIf="showlogout()">
    <a class="mx-2 pointer icon-text" (click)="logout()">
      logout <mat-icon class="pointer" aria-hidden="false" aria-label="Logout" inline=true>exit_to_app</mat-icon>
    </a>
   </div>
</mat-toolbar>