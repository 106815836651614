import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GeoService {
  api = 'http://www.universal-tutorial.com/api/';
 
  constructor(private http:HttpClient) { 
  }

  countries(){
    return this.http.get(this.api + 'countries');
  }
  states(country:string){
    return this.http.get(this.api + country + '/states');
  }
  
  cities(state:string){
    return this.http.get(this.api + state + '/cities');
  }



}
