import { GeoService } from './../../../services/geo.service';
import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {

  hospital;
  selectedIndex = 0;

  constructor(private geo: GeoService, private api: ApiService, private router: Router) {
    if (this.api.user['passcode']) {
      this.hospital = this.api.user;
    }
    else if (localStorage.getItem('passcode')) {
      this.hospital = {
        passcode: localStorage.getItem('passcode'),
        hospitalDetails: {
          name: '',
          address: '',
          streeName: '',
          streetNo: '',
          city: '',
          county: '',
          state: '',
          country: '',
          location: {
            longitude: '',
            latitude: ''
          }
        },
        admin: {
          name: '',
          login: '',
          password: '',
          email: ''
        },
        ventilators: {
          total: 0,
          inUse: 0,
          available: 0
        }
      };
    }
  }


  ngOnInit() {
  }

  public onChange(address) {
    let theAddress = {};
    if (address.photos && address.photos.length > 0) {
      console.dir(address.photos[0].getUrl({ maxHeight: 500, maxWidth: 500 }));
    }
    address.address_components.forEach(element => {
      theAddress[element.types[0]] = element.long_name
    });

    console.log(theAddress);
    this.hospital.hospitalDetails.address = address.formatted_address;
    this.hospital.hospitalDetails.city = theAddress['locality'];
    this.hospital.hospitalDetails.country = theAddress['country'];
    this.hospital.hospitalDetails.county = theAddress['administrative_area_level_2'];
    this.hospital.hospitalDetails.location.latitude = address.geometry.viewport.Za.i;
    this.hospital.hospitalDetails.location.longitude = address.geometry.viewport.Ua.i;
    this.hospital.hospitalDetails.name = address.name;
    this.hospital.hospitalDetails.state = theAddress['administrative_area_level_1'];
    // this.hospital.hospitalDetails.streeName =
    this.hospital.hospitalDetails.streetNo = theAddress['street_number'];
    this.hospital.hospitalDetails.streeName = theAddress['route'];

  }

  submit() {
    if(localStorage.getItem('passcode')){
      this.api.validateObserve(localStorage.getItem('passcode')).subscribe(data=>{
        if (!data || data['role'] !== 'hospital'){
          this.router.navigateByUrl('home');
        }else{
          return this.api.post_hospital(this.hospital);          
        }
      });       
    }
    else{
      return this.api.put_hospital(this.hospital);
    }
  }

  calcVentilators(){
    this.hospital.ventilators.available = this.hospital.ventilators.total - this.hospital.ventilators.inUse; 
  }

}
