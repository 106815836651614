    <div mat-button mat-dialog-close class="model-close">
      <mat-icon>close</mat-icon>
    </div>
    <div>
      <mat-form-field class="mx-2" floatLabel="never">
        <input matInput placeholder='Username' [(ngModel)]="data['login']">
      </mat-form-field>
      <mat-form-field class="mx-2" floatLabel="never">
        <input matInput placeholder='password' type="password" [(ngModel)]="data['password']">
      </mat-form-field>
      <button mat-stroked-button [mat-dialog-close]="data">Login</button>
    </div>

    <div class="col-md-4 pt-3">

    </div>
