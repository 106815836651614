<app-title title='Hospital Dashboard'></app-title>

<div class="container">
  <mat-tab-group class="pt-3" [(selectedIndex)]="selectedIndex">
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">list_alt</mat-icon>Hospital Details
      </ng-template>

      <!-- form -->
      <div class="col-md-8 offset-md-2 mt-5">
        <mat-form-field class="col-md-12">
          <input matInput placeholder="Name of hospital" ngx-google-places-autocomplete
            (onAddressChange)="onChange($event)" [(ngModel)]="hospital.hospitalDetails.name">
            <mat-hint>Type the name of your hospital here and the address will auto-populate.</mat-hint>
        </mat-form-field>
        <mat-form-field class="col-md-12 d-none" >
          <mat-label>Hospital address</mat-label>
          <textarea matInput [(ngModel)]="hospital.hospitalDetails.address"></textarea>
        </mat-form-field>
        <mat-form-field class="col-md-6">
          <input matInput placeholder="Street Number" [(ngModel)]="hospital.hospitalDetails.streetNo">
        </mat-form-field>
        <mat-form-field class="col-md-6">
          <input matInput placeholder="Street name" [(ngModel)]="hospital.hospitalDetails.streeName">
        </mat-form-field>
        <mat-form-field class="col-md-6">
          <input matInput placeholder="County" [(ngModel)]="hospital.hospitalDetails.county">
        </mat-form-field>
        <mat-form-field class="col-md-6">
          <input matInput placeholder="City" [(ngModel)]="hospital.hospitalDetails.city">
        </mat-form-field>
        <mat-form-field class="col-md-6">
          <input matInput placeholder="State" [(ngModel)]="hospital.hospitalDetails.state">
        </mat-form-field>
        <mat-form-field class="col-md-6">
          <input matInput placeholder="Country" [(ngModel)]="hospital.hospitalDetails.country">
        </mat-form-field>
      </div>

      <!-- Submit -->
      <div class="container text-center">
        <button mat-raised-button color="primary" (click)="selectedIndex=1">Next <mat-icon class="example-tab-icon">
            arrow_right_alt</mat-icon></button>
      </div>

    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">verified_user</mat-icon> Admin
      </ng-template>
      <!-- form -->
      <div class="col-md-8 offset-md-2 mt-5">
        <mat-form-field class="col-md-12">
          <input matInput placeholder="Name of hospital administrator" [(ngModel)]='hospital.admin.name'>
        </mat-form-field>

        <mat-form-field class="col-md-12">
          <input matInput placeholder="Username" [(ngModel)]='hospital.admin.login'>
          <mat-hint>Please create a username.</mat-hint>
        </mat-form-field>
        
        <mat-form-field class="col-md-12">
          <input matInput placeholder="Password" [(ngModel)]='hospital.admin.password' type="password">
          <mat-hint>Please create a password. Your password must have at least 6 characters.</mat-hint>
        </mat-form-field>

        <mat-form-field class="col-md-12">
          <input matInput placeholder="Email" [(ngModel)]='hospital.admin.email' type="email">
        </mat-form-field>
      </div>

      <!-- Submit -->
      <div class="container text-center">
        <button mat-raised-button color="primary" (click)="selectedIndex=2">Next <mat-icon class="example-tab-icon">
            arrow_right_alt</mat-icon></button>
      </div>


    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">check_box</mat-icon> Available Ventilators Information
      </ng-template>

      <div class="col-md-8 offset-md-2 mt-5">
        <h5 class="col-md-12">Please record the total number of ventilators at your hospital, as well as how
          many are currently in use</h5>

          <mat-form-field class="col-md-12">
          <input matInput placeholder="Total number of ventilators" [(ngModel)]='hospital.ventilators.total'
            type="number" (keyup)="calcVentilators()">
        </mat-form-field>

        <mat-form-field class="col-md-12">
          <input matInput placeholder="Number of ventilators in use" [(ngModel)]='hospital.ventilators.inUse'
            type="number" (keyup)="calcVentilators()">
        </mat-form-field>

        <mat-form-field class="col-md-12">
          <input matInput placeholder="Number of ventilators available for use"
            [(ngModel)]='hospital.ventilators.available' type="number">
        </mat-form-field>
      </div>

      <!-- Submit -->
      <div class="container text-center">
        <button mat-raised-button color="primary" (click)="submit()">Submit</button>
      </div>

    </mat-tab>

  </mat-tab-group>
</div>

<app-footer></app-footer>
