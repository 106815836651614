
<div mat-button mat-dialog-close class="model-close">
  <mat-icon>close</mat-icon>
</div>
<h1 mat-dialog-title>
  Enter the passcode</h1>
<div class="row">
  <mat-form-field class="col-md-8">
    <mat-label>your passcode</mat-label>
    <input matInput [(ngModel)]="data['passcode']" type="password">
  </mat-form-field>
  <div class="col-md-4 pt-3">
    <button mat-stroked-button [mat-dialog-close]="data['passcode']">Proceed</button>
  </div>
</div>

  <!-- <div mat-dialog-close class="model-close col-md-12">
    Already have an account? login here.
  </div> -->


