<app-title title='Welcome'></app-title>
<div class="container">
    <div class="col-md-12 mv-5">
      <div class="col-md-8 offset-md-2 pointer my-3">
         <div class="text-center mb-3 welcome-text">
           Created by students and faculty at <strong>Cleveland State University</strong> and made available
           <strong>free</strong> of
           charge to government, public health officials, and hospitals around the world.
           Please contact countsupplies@csuohio.edu for more information.
         </div>

        <video width="99%" height="auto" controls>
          <source src="../../../assets/CountSuppliesVideo_FULL.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 offset-md-2">
        <h3 (click)="openDialog('hospital')" class="pointer m-1">Register Hospital System
        </h3>
      </div>
      <div class="col-md-4">
        <h3 (click)="openDialog('governer')" class="pointer m-1">Governor's View
        </h3>
      </div>
    </div>
  </div>
