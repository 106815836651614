import { Injectable, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  api = 'http://countsupplies.org:8000/api/';
  user = {};
  constructor(private http: HttpClient, private _snackBar: MatSnackBar, private Router: Router) { }

  get_hospitals(){
    return this.http.get(this.api+'hospitals');
  }

  get_hospitals_filter(state){
    return this.http.get(this.api +'hospital/state/'+state);
  }

  login(query){
    this.http.post(this.api+'login', query).subscribe(result=>{
      if(result[0]){
        localStorage.setItem('_id', result[0]._id);
        this.user = result[0];
        this._login();
      }else{
        this._snackBar.open('Invalid Passcode', 'close');
      }
    });
  }

  _login(){
    this._snackBar.open('Logged In', 'close');
    this.Router.navigateByUrl('hospital/dashboard');
  }

  put_hospital(hospital){
    this.http.put(this.api+'hospitals', hospital).subscribe(result=>{
      this._snackBar.open('Your details are updated successfully.', 'close')
    },
    (err)=>{
      this._snackBar.open('Something went wrong. Your details are not updated.','close')
    },
    ()=>{

    }
    )
  }

  post_hospital(hospital) {
    this.http.post(this.api + 'hospitals', hospital).subscribe(result => {
      this._snackBar.open('Your details are saved successfully. Please Login.', 'close');
      localStorage.removeItem('passcode');
      this.Router.navigateByUrl('/home');
    },
      (err) => {
        this._snackBar.open('Something went wrong. Your details are not updated.', 'close')
      },
      () => {

      }
    )
  }

  validate(passcode, role){
    this.http.get(this.api +'passcode/'+ passcode).subscribe(data=>{
      if (!data || data['role'] !== role){
        this._snackBar.open('Invalid Passcode', 'close');
        this.Router.navigateByUrl('home');
      }
      else{
        this._snackBar.open('Logged In', 'close');
        localStorage.setItem('passcode', passcode);
        if(role=='admin'){
          this.Router.navigateByUrl('dashboard');
        }
        else{
          this.Router.navigateByUrl('hospital/dashboard');
        }
      }
    },
    (err)=>{
      this._snackBar.open('Invalid Passcode', 'close');
      this.Router.navigateByUrl('home');
    },
    ()=>{

    }
    )
  }

  validateObserve(passcode){
    return this.http.get(this.api + 'passcode/' + passcode);
  }

}
