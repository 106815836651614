<app-title [title]='state'></app-title>

<div class="container">
    <div class="row cards">
        <div class="col-md-4 mt-5 blue" primary>
            <h1 class="my-0">{{highlight.total}}</h1>
            Total ventilators
        </div>
        <div class="col-md-4 mt-5 red">
            <h1 class="my-0">{{highlight.inUse}}</h1>
            Currently in Use
        </div>
        <div class="col-md-4 mt-5 green">
            <h1 class="my-0">{{highlight.available}}</h1>
            Available for use
        </div>
    </div>

    <mat-tab-group (selectedTabChange)="matTabClick($event)" class="pt-3">

      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon">toc</mat-icon>
        </ng-template>
        
        <!-- Table -->
        <m-table #tablex [value]="cities" [expandable]="true" expandMultiple="true" (onFilter)="onFilter($event)">
            <m-header title="Hospitals" globalSearch="true" searchField="box" export="true"></m-header>
            <m-footer ></m-footer>
            <m-column field="country" header="Country"  sortable="true"></m-column>
            <m-column field="state" header="State"  sortable="true"></m-column>
            <m-column field="city" header="City" sortable="true"></m-column>
            <m-column field="county" header="County" sortable="true"></m-column>
            <m-column field="name" header="Hospital" sortable="true"></m-column>
            <m-column field="total" header="Total Ventilators" sortable="true"></m-column>
            <m-column field="inUse" header="Ventilators in use" sortable="true"></m-column>
            <m-column field="available" header="Ventilators Available" sortable="true"></m-column>
            <ng-template let-row let-index="rowIndex" mTemplate="expansion">
              <!-- {{index}} - {{row}} -->
                <div><strong>Address: </strong> {{tablex['value'][index].address | json}}</div>          
            </ng-template>

        </m-table>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon">location_on</mat-icon>
        </ng-template>
        <!-- Google Map -->
        <agm-map #AgmMap
                  [latitude]="mapObj.lat"
                  [longitude]="mapObj.lng"
                  [zoom]="mapObj.zoom"
                  [disableDefaultUI]="false"
                  (mapClick)="mapClicked($event)">
                  <agm-marker 
                    *ngFor="let marker of mapObj.markers; let i = index"
                    [latitude]="marker.lat"
                    [longitude]="marker.lng"
                    [agmFitBounds]="true">
                    <agm-info-window>
                      <p> <strong> {{marker.name}}</strong></p>
                      <p> <strong>{{marker.address}}</strong></p>
                      <p> <strong> Total number of ventilators </strong>: <span class="blue">  {{marker.ventilators.total}} </span> </p>
                      <p> <strong> Number of ventilators in use </strong>:<span class="red"> {{marker.ventilators.inUse}} </span></p>
                      <p> <strong>Number of ventilators available for use</strong>:<span class="green"> {{marker.ventilators.available}} </span> </p>
                    </agm-info-window>
                  </agm-marker>
        </agm-map>     
      </mat-tab>

    </mat-tab-group>

</div>

<app-footer></app-footer>

