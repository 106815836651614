<div class="container pb-5">
  <div class="col-md-10 offset-md-1">
    <div class="container">
      <div class="col-md-9 m-0 p-0">
        <h4 class="intro-text">Countsupplies.org allows hospitals to quickly and easily register and update their
          inventory of supplies like
          ventilators both in use and available for use daily so that public health authorities can track the number of
          available supplies each day by city, county and state</h4>
      </div>
    </div>
    <div class="container">
      <div class="offset-md-3 col-md-9 p-0" style="column-count: 2; text-align: justify;
  text-justify: inter-word;">
        Launched March 26, 2020, the project builds off of the development of drughelp.care, which seeks to better
        connect individuals seeking drug treatment with available spaces in local treatment facilities. It was supported
        by CSU’s Office of Research and a Centers for Disease Control and Prevention Data to Action grant through the
        Cuyahoga County Board of Health.
      </div>
    </div>

    <div class="container">
      <h2>THE CREATORS</h2>
      <h5 class="desc">
        Dr. Patricia Stoddard Dare, professor of social work, and Dr. Miyuki Fukushima Tedor, associate professor of
        criminology, anthropology, and sociology, developed the specifications for this site with technical specs
        designed by Computer and Information Science student, Abhijeet Bhimrao Tupe under the mentorship of Computer
        Science Professor Dr. Sathish Kumar.
      </h5>

      <div class="row mx-0">
        <div class="col-md-3 pl-0 pb-2">
          <div class="creator_image ">
            <img src="../../assets/imgs/people/Patricia.a2626dc1.jpg" />
          </div>
          <div class="name">Patricia Stoddard Dare</div>
          <div class="description">Professor of Social Work</div>
        </div>

        <div class="col-md-3 pl-0 pb-2">
          <div class="creator_image ">
            <img src="../../assets/imgs/people/Miyuki.94ceed7e.png" />
          </div>
          <div class="name">Miyuki Fukushima Tedor</div>
          <div class="description">Associate Professor of Criminology</div>
        </div>

        <div class="col-md-3 pl-0 pb-2">
          <div class="creator_image ">
            <img src="../../assets/imgs/people/thumbnail_AP Sathish Kumar Photo.jpg" />
          </div>
          <div class="name">Sathish Kumar</div>
          <div class="description">Associate Professor of Computer Science
            Engineering</div>
        </div>

      </div>
    </div>

    <div class="container">
      <h2>THE ENGINEERING TEAM</h2>
      <div class="row mx-0">
        <div class="col-md-3 pl-0 pb-2">
          <div class="creator_image ">
            <img src="../../assets/imgs/people/thumbnail_IMG_3376.jpg" />
          </div>
          <div class="name">Abhijeet Tupe</div>
          <div class="description">Software Development Engineer
            <!-- : Masters in Software Engineering, Cleveland State University  -->
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <h2>THE GRAPHIC DESIGN TEAM</h2>
      <div class="row mx-0">

        <div class="col-md-3 pl-0 pb-2">
          <div class="creator_image ">
            <img src="../../assets/imgs/people/gia.69c54b7c.jpg" />
          </div>
          <div class="name">Gia Paulovich</div>
          <div class="description">Graphic Designer</div>
        </div>

        <div class="col-md-3 pl-0 pb-2">
          <div class="creator_image ">
            <img src="../../assets/imgs/people/kyra_wells.71f13d9c.png" />
          </div>
          <div class="name">Kyra Wells</div>
          <div class="description">Graphic Designer</div>
        </div>

      </div>
    </div>

  </div>
</div>

<app-footer></app-footer>
