import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { MatSnackBar, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { DialogData } from 'src/app/pages/home/home.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  login = {
    login: '',
    password: ''
  }
  showMenu = false;

  constructor(public dialog: MatDialog, public router: Router, public api: ApiService, private _snackBar: MatSnackBar,) {
    
  }

  ngOnInit() {
   }

  logout(){
    localStorage.clear();
    this.api.user = {};
    this.router.navigateByUrl('/');
  }

  doLogin(){
    localStorage.clear();
    if (this.login.login == '' || this.login.password ==''){
      if (this.login.login == ''){
        this._snackBar.open('Invalid username', 'close');
      }
      else{
        this._snackBar.open('Invalid password', 'close');
      }
    }
    else{
      this.api.login(this.login);
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(loginModel, {
      data: {
        login: this.login.login,
        password: this.login.password
      },
      width: '530px'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.login = result;
      this.doLogin();
    });
  }

  showlogout(){
    if (this.api.user['_id'] || localStorage.getItem('passcode')){
      return true;
    }
    return false;
  }

}

@Component({
  selector: 'login-model',
  templateUrl: 'login-model.html',
})
export class loginModel {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) { }
}

@Component({
  selector: 'video-model',
  templateUrl: 'video.html',
})
export class videoModel {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) { }
}